
import {defineComponent,ref,reactive,toRefs, onMounted, onBeforeMount,} from "vue";
import { FolderOutlined,VerticalAlignBottomOutlined,DownloadOutlined} from '@ant-design/icons-vue';
import { getArticleDetails } from '@/api/grow';
import { useRoute ,LocationQueryValue} from "vue-router";
export default defineComponent({
    name:"details",
    components:{
        FolderOutlined,
        VerticalAlignBottomOutlined,
        DownloadOutlined
    },
    setup(){
        const route = useRoute()
        const userData = ref({})
        const text = ref('11')

        const activeKey = ref(['1'])

        onBeforeMount(()=>{
            details(route.query.id)
        })

        const details = (id: LocationQueryValue|LocationQueryValue[]) => {
            getArticleDetails({ id }).then((res: any) => {
                if(res.code == 200){
                    userData.value = res.data;
                }
            });
        }

        const downLoad = (val:any)=>{
            fetch(val.accessory_url).then((res) => {
                res.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob)
                    const a = document.createElement('a')
                    a.href = url
                    a.download =  val.name + val.exc;
                    a.click()
                    window.URL.revokeObjectURL(url)
                })
            })
        }

        return {
            userData,
            text,
            downLoad,
            activeKey
        }
    }
})
